import { Route, Switch } from 'react-router-dom';
import Home from '../components/Home';
import Contact from '../components/Contact';
import About from '../components/About'
import Team from "../components/Team";
// import NFT from "../components/NFT";
const Routes = () => {
      return (
        <Switch>                
            <Route exact path="/" component={Home}/>
            <Route path="/home" component={Home}/>  
            <Route path='/team' component={Team}/>   
            <Route path='/contact' component={Contact}/>  
            <Route path='/about' component={About}/>
            {/* <Route path='/NFT' component={NFT}/>                       */}
        </Switch>
      );
    
  }
  
  export default Routes;
