
import React, { Component } from 'react'
import '../css/Navbar.css'
import logo from '../image/logo.png'
import { Navbar, Nav } from 'react-bootstrap';
class Navigation extends Component {

  render() {
    return (
      <div style={{ backgroundColor: '#233043', border: 0, position:'sticky',top:0,width:'100%' }}> 
        <Navbar collapseOnSelect expand='lg' className="align">
          <Navbar.Brand href="/home"><img src={logo} alt="Naitsirc Industries" height="65" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav blue" style={{ backgroundColor: 'dark', border: 0 }} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className='ml-auto nav-fill w-100' >
              <Nav.Link className="text-white" href='/home'>HOME</Nav.Link>
              <Nav.Link className="text-white" href='/team'>TEAM</Nav.Link>
              <Nav.Link className="text-white" href='/about'>ABOUT</Nav.Link>
              <Nav.Link className="text-white" href='/contact'>CONTACT</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;