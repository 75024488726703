import React, { Component } from 'react'
import '../css/Home.css'
import thunder from '../image/thunder.webp'
import engine from '../image/engine.webp'
import technology from '../image/technology.webp'
import bio_e from '../image/bio_e.webp'
class Home extends Component {

  render() {
    return (
      <div>
        <p className='m-5 mb-0 text-white'>NAITSIRC INDUSTRIES</p>
        <h1 className='text-white m-5 mt-0 mb-0'>CHANGING THE WORLD</h1>
        <h1 className='text-white m-5 mt-0'>FOR A BETTER FUTURE</h1>
        <div className='section-container'>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="xd">
                  <a className="img-card" href="/#" >
                    <img src={thunder} alt="duck and bulb" />
                  </a>
                  <div className="card-content">
                    <h4 className="card-title">
                      <a href="/#"> Alternative Energy
                      </a>
                    </h4>
                    <p>
                      Create clean, balanced energy for everyone.
                    </p>
                  </div>
                  <div className="card-read-more">
                    <a href="/#" className="btn btn-link btn-block">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="xd">
                  <a className="img-card" href="/#">
                    <img src={engine} alt="airplane engine" />
                  </a>
                  <div className="card-content">
                    <h4 className="card-title">
                      <a href="/#"> Advanced technology
                      </a>
                    </h4>
                    <p>
                      Innovation, invention, think. Inventing innovative technology. #beInnovative
                    </p>
                  </div>
                  <div className="card-read-more">
                    <a href="/#" className="btn btn-link btn-block">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="xd">
                  <a className="img-card" href="/#">
                    <img src={technology} alt='phone and robotics arm' />
                  </a>
                  <div className="card-content">
                    <h4 className="card-title">
                      <a href="/#"> IT Computer Science
                      </a>
                    </h4>
                    <p>
                      Software programming has something of a mystery. #coding
                    </p>
                  </div>
                  <div className="card-read-more">
                    <a href="/#" className="btn btn-link btn-block">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="xd">
                  <a className="img-card" href="/#">
                    <img src={bio_e} alt='robot' />
                  </a>
                  <div className="card-content">
                    <h4 className="card-title">
                      <a href="/#">Engineering
                      </a>
                    </h4>
                    <p>
                      Improving the quality of life for everyone, thanks all engineering.
                    </p>
                  </div>
                  <div className="card-read-more">
                    <a href="/#" className="btn btn-link btn-block">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Home;