
import React, { Component } from 'react'
import '../css/Team.css'
class Team extends Component {


  render() {
    return (
      <div>
        <h1 className='text-center' style={{ color: '#d46554' }}>THE FACES OF NAITSIRC INDUSTRIES</h1>
        <div className='section mt-3'>
          <div className="container">
            <div className="row xd">
              <div className="col-xl-3 col-lg-3 col-md-6">
                <a className="img-c" href="https://twitter.com/naitsirC_B" target={'_blank'} rel="noreferrer">
                  <img src='/' alt="Cristian Boffa" />
                </a>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="card-content">
                  <h3 className="card-title">
                    <a href="https://www.linkedin.com/in/cristian-boffa-8a658a1a4" target={'blank'} > CRISTIAN BOFFA
                    </a>
                  </h3>
                  <h5 className='toppest'>OWNER</h5>
                  <p style={{ marginLeft: '1%' }}>
                    CEO of the idea, Naitsirc Industries. Student of the Rzeszów University of Technology.
                    I spend my time coming up with crazy ideas to expand the limits of possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;